import styles from "./SectorMoves.module.scss";
import { getStockMoves } from "@/controllers/stock-moves";
import { openMenuState, themeState, positiveState, negativeState, regionState, lighterBackgroundColorState, greyDarkerState, greyState, textColorState, userState, backgroundColorState } from "@/lib/store";
import { classNames } from "@/lib/utils";
import Highcharts, { Chart, Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { RefObject, useContext, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import Color from "@/lib/Color";
import { ModuleContext } from "@/lib/Context";
import Skeleton from "../skeleton/Skeleton";
import { chartHighContrastDarkTheme, chartHighContrastLightTheme, checkTheme, getCSVData, internationalize } from "@/lib/highcharts.src";
import { Typography, Chip } from "@mui/material";
import ResizeObserver from "resize-observer-polyfill";

const regions = { 7: 53651, 10: 53652 };

interface SectorMovesProps {
    indexId?: number;
    description?: string;
    activeTimerange?: string;
}

const hardCodedPieData = [
    [
        "EnBBhxpvgL3",
        0.542622,
        1175024357562
    ],
    [
        "Inf3v4aXYBp1ghJ2nMw",
        0.46465600000000007,
        9815298633964
    ],
    [
        "UtU4iALB8H1FzH4N",
        -0.4560760000000001,
        735507112638
    ],
    [
        "Gas4KradaLQ0C1r",
        0.32890099999999994,
        2325583674018
    ],
    [
        "ComTAtvDzK1V2gT7sFSiPrvx",
        0.20189799999999997,
        3249350968047
    ],
    [
        "Rea0am3FLgY2oKr",
        0.12563100000000002,
        942009181587
    ],
    [
        "He4K7lthLQ0C1r",
        0.10683099999999995,
        3122030047518
    ],
    [
        "FinT7cF5VGrU1l",
        0.10203399999999996,
        4339925632610
    ],
    [
        "MatSxU7L7mG5",
        0.04655799999999997,
        955198784293
    ],
    [
        "ConsT5m3mM9X2DeW7",
        -0.029412999999999967,
        5356653675034
    ],
    [
        "Cons8iH3bT5pS7zZ4n",
        0.00038900000000000566,
        3720405031138
    ]
];


const SectorMoves = ({ indexId, description = "", activeTimerange }: SectorMovesProps) => {
    const { obfuscated, setObfuscated } = useContext(ModuleContext);

    const theme = useRecoilValue(themeState);
    const grey = useRecoilValue(greyState);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);
    const textColor = useRecoilValue(textColorState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const openMenu = useRecoilValue(openMenuState);
    const region = useRecoilValue(regionState);
    const whiteDarker = new Color(greyDarker).brighten(0.59).get() as string;
    const user = useRecoilValue(userState);
    const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);
    const backgroundColor = useRecoilValue(backgroundColorState);

    const chartRef = useRef<{ chart: Chart; container: RefObject<HTMLDivElement>; }>();
    const sectorMovesChartContainerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [highContrast, setHighContrast] = useState<boolean>(user.settings.module_settings["HighContrast->/settings"]?.value);
    const [internationalization, setInternationalization] = useState<string>(user.settings.module_settings["Internationalization->/settings"]?.value);
    const [sonification, setSonification] = useState<boolean>(!!user.settings.module_settings["Sonification->/settings"]?.value);

    const [cognitiveAccess, setCognitiveAccess] = useState<boolean>(user.settings.module_settings["CognitiveAccess->/settings"]?.value);

    const [options, setOptions] = useState<Options>({
        colors: highContrast ? (theme === "light" ? chartHighContrastLightTheme.colors : chartHighContrastDarkTheme.colors) : [],
        chart: {
            backgroundColor: "transparent",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            style: {
                fontSize: "10px",
            },
        },
        stockTools: {
            gui: {
                enabled: false,
            },
        },
        title: {
            style: {
                color: textColor,
                fontWeight: "bold",
                fontSize: "13px",
            },
            text: "Intraday sector moves",
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br/><span>Move: <span style="color:{point.color}"><b>{point.custom.x}</b></span></span>',
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
            description: description,
            announceNewData: {
                enabled: true,
            },
        },
        plotOptions: {
            pie: {
                size: "55%",
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    color: grey,
                    enabled: true,
                    format: "<b>{point.name}</b>:<br/><span style='color:{point.color}'><b>{point.custom.x}</b></span>",
                },
            },
        },
        credits: { enabled: false },
        exporting: {
            menuItemDefinitions: {
                // Custom definition

                downloadPNG: {
                    text: 'Download PNG',
                    onclick: function () {
                        this.exportChart({
                            type: 'image/png'
                        }, options);
                    }
                },
                downloadJPEG: {
                    text: 'Download JPEG',
                    onclick: function () {
                        this.exportChart({
                            type: 'image/jpeg'
                        }, options);
                    }
                },
                downloadPDF: {
                    text: 'Download PDF',
                    onclick: function () {
                        this.exportChart({
                            type: 'application/pdf'
                        }, options);
                    }
                },
                downloadSVG: {
                    text: 'Download SVG',
                    onclick: function () {
                        this.exportChart({
                            type: 'image/svg+xml'
                        }, options);
                    }
                },
                downloadCSV: {
                    text: "Download CSV",
                    onclick: () => getCSVData(chartRef.current.chart)
                },

            },
            enabled: !!cognitiveAccess,
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "downloadCSV", "downloadXLS", !!sonification ? "downloadMIDI" : "", !!sonification ? "playAsSound" : "",],
                    symbolStrokeWidth: 2,
                    symbolStroke: textColor,
                    theme: {
                        fill: theme === "light" ? backgroundColor : lighterBackgroundColor,
                        // @ts-ignore
                        color: textColor,
                        states: {
                            hover: { fill: theme === "light" ? backgroundColor : lighterBackgroundColor },
                            select: { fill: theme === "light" ? backgroundColor : lighterBackgroundColor },
                        },
                    },
                },
            },
        },
    });

    const getColor = (num: number): string => {
        const abs = Math.abs(num);
        let brighten = 0;
        if (abs >= 2.5) brighten = -0.15;
        else if (abs < 1.25) brighten = 0.15;
        return String(new Color(num < 0 ? negative : positive).brighten(brighten).get());
    };

    useEffect(() => {
        if (region) {
            let days_back: number;
            let timeRange: string;
            switch (activeTimerange) {
                case "3m":
                    days_back = 90;
                    timeRange = "3 months";
                    break;
                case "6m":
                    days_back = 180;
                    timeRange = "6 months";
                    break;
                case "1m":
                    days_back = 30;
                    timeRange = "1 month";
                    break;
                case "1w":
                    days_back = 7;
                    timeRange = "3 months";
                    break;
                case "2m":
                    days_back = 60;
                    timeRange = "2 months";
                    break;
                case "1y":
                    days_back = 365;
                    timeRange = "1 year";
                    break;
                case "2y":
                    days_back = 365 * 2;
                    timeRange = "2 years";
                    break;
                default:
                    days_back = 0;
                    break;
            }

            setIsLoading(true);
            if (user.email !== "visitor@ortex.com" && user.subscription_plan !== "ORTEX Free") {
                getStockMoves(indexId ? indexId : regions[region], {
                    days_back: days_back,
                    type: "sector",
                })
                    .then((res) => {
                        setObfuscated(res.obfuscated);
                        setOptions({
                            ...options,
                            title: {
                                style: {
                                    color: textColor,
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                },
                                text: `${timeRange || "Intraday"} -  Sector Moves`,
                            },
                            series: [
                                {
                                    type: "pie",
                                    name: "Market Cap",
                                    data: res.rows?.map((value) => {
                                        return highContrast
                                            ? {
                                                name: value[0],
                                                y: Number(value[2]),
                                                custom: { x: Number(value[1]).toFixed(2) },
                                            }
                                            : {
                                                name: value[0],
                                                y: Number(value[2]),
                                                color: getColor(Number(value[1])),
                                                custom: { x: Number(value[1]).toFixed(2) + "%" },
                                            };
                                    }),
                                    dataLabels: {
                                        y: -5,
                                        x: 0,
                                        padding: 0.65,
                                        style: {
                                            fontWeight: "lighter",
                                            fontSize: "13px",
                                            textOutline: "none",
                                        },
                                    },
                                },
                            ],
                        });
                    })
                    .finally(() => setIsLoading(false));
            } else {
                setObfuscated(true);
                setOptions({
                    ...options,
                    title: {
                        style: {
                            color: textColor,
                            fontWeight: "bold",
                            fontSize: "13px",
                        },
                        text: `${timeRange || "Intraday"} -  Sector Moves`,
                    },
                    series: [
                        {
                            type: "pie",
                            name: "Market Cap",
                            data: hardCodedPieData?.map((value) => {
                                return highContrast
                                    ? {
                                        name: value[0],
                                        y: Number(value[2]),
                                        custom: { x: Number(value[1]).toFixed(2) },
                                    }
                                    : {
                                        name: value[0],
                                        y: Number(value[2]),
                                        color: getColor(Number(value[1])),
                                        custom: { x: Number(value[1]).toFixed(2) + "%" },
                                    };
                            }),
                            dataLabels: {
                                y: -5,
                                x: 0,
                                padding: 0.65,
                                style: {
                                    fontWeight: "lighter",
                                    fontSize: "13px",
                                    textOutline: "none",
                                },
                            },
                        },
                    ],
                });
                setIsLoading(false);
            }
        }

    }, [user.email, user.subscription_plan, region, indexId, activeTimerange]);

    useEffect(() => {
        checkTheme(highContrast, theme, setOptions, options, cognitiveAccess, textColor, theme === "light" ? backgroundColor : lighterBackgroundColor, false, !!sonification);
    }, [theme]);

    useEffect(() => {
        setTimeout(() => {
            chartRef.current?.chart?.reflow();
        }, 500);
    }, [openMenu]);

    useEffect(() => {
        internationalize(internationalization);
        if (sectorMovesChartContainerRef.current) {
            const observer = new ResizeObserver(() => {
                chartRef.current?.chart.reflow();
            });
            observer.observe(sectorMovesChartContainerRef.current);
            return () => observer.disconnect();
        }
    }, []);

    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            legend: {
                enabled: !!cognitiveAccess,
                itemStyle: { color: grey },
            },
            exporting: {
                menuItemDefinitions: {
                    // Custom definition

                    downloadPNG: {
                        text: 'Download PNG',
                        onclick: function () {
                            this.exportChart({
                                type: 'image/png'
                            }, options);
                        }
                    },
                    downloadJPEG: {
                        text: 'Download JPEG',
                        onclick: function () {
                            this.exportChart({
                                type: 'image/jpeg'
                            }, options);
                        }
                    },
                    downloadPDF: {
                        text: 'Download PDF',
                        onclick: function () {
                            this.exportChart({
                                type: 'application/pdf'
                            }, options);
                        }
                    },
                    downloadSVG: {
                        text: 'Download SVG',
                        onclick: function () {
                            this.exportChart({
                                type: 'image/svg+xml'
                            }, options);
                        }
                    },
                    downloadCSV: {
                        text: 'Download CSV',
                        onclick: () => getCSVData(chartRef.current.chart)
                    },

                },
                enabled: !!cognitiveAccess,
                buttons: {
                    contextButton: {
                        symbolStrokeWidth: 2,
                        symbolStroke: textColor,
                        theme: {
                            fill: theme === "light" ? backgroundColor : lighterBackgroundColor,
                            // @ts-ignore
                            color: textColor,
                            states: {
                                hover: { fill: theme === "light" ? backgroundColor : lighterBackgroundColor },
                                select: { fill: theme === "light" ? backgroundColor : lighterBackgroundColor },
                            },
                        },
                    },
                },
            },
        }));
    }, [cognitiveAccess]);

    useEffect(() => {
        if (user.settings.module_settings["CognitiveAccess->/settings"]?.value) {
            setCognitiveAccess(user.settings.module_settings["CognitiveAccess->/settings"]?.value);
        } else {
            setCognitiveAccess(false);
        }
    }, [user.settings.module_settings["CognitiveAccess->/settings"]?.value]);

    useEffect(() => {
        if (user.settings.module_settings["Sonification->/settings"]?.value) {
            setSonification(user.settings.module_settings["Sonification->/settings"]?.value);
        } else {
            setSonification(false);
        }
    }, [user.settings.module_settings["Sonification->/settings"]?.value]);

    return (
        <div className={styles.SectorMoves} ref={sectorMovesChartContainerRef}>
            {isLoading ? (
                <Skeleton variant="rounded" width="100%" height="100%" loading={isLoading} />
            ) : (
                <>
                    <div className={classNames(styles.chart, obfuscated ? "obfuscated" : null)}>
                        <HighchartsReact highcharts={Highcharts} constructorType="chart" options={options} ref={chartRef} />
                    </div>
                    <div className={styles.parent}>
                        <div className={styles.div1}>
                            <Typography variant="overline" sx={{ pr: 0.5 }} className={styles.sectorMovesLabelFonts}>
                                Colour by Move
                            </Typography>
                        </div>
                        <div className={styles.div2}>
                            <Typography variant="overline" sx={{ pr: 0.5 }} className={styles.sectorMovesLabelFonts}>
                                Size By Market Cap
                            </Typography>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default SectorMoves;
